<template>
  <div class="qr-code-history">
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Lịch sử thanh toán QR code'">
          <template v-slot:preview>
            <b-row class="mb-5">
              <b-col>
                <b-input
                  placeholder="Tìm kiếm theo mã đơn hàng"
                  v-model="orderNumber"
                  size="sm"
                ></b-input>
              </b-col>
              <b-col>
                <b-input
                  placeholder="Tìm kiếm theo mã hóa đơn"
                  v-model="billNumber"
                  size="sm"
                ></b-input>
              </b-col>
              <b-col>
                <b-input
                  placeholder="Tìm kiếm theo mã xác thực"
                  v-model="verificationCode"
                  size="sm"
                ></b-input>
              </b-col>
              <b-col>
                <div class="search-col">
                  <b-input-group>
                    <date-picker
                      placeholder="Từ ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="dateFrom"
                    ></date-picker>
                  </b-input-group>
                  <span class="ml-1 mr-1"></span>
                  <b-input-group>
                    <date-picker
                      placeholder="Tới ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="dateTo"
                    ></date-picker>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="fetchData"
                >Lọc</b-button>
              </b-col>
            </b-row>
            <table class="table table-bordered-list table-hover col-md-12">
              <thead>
                <tr>
                  <th>Cửa hàng</th>
                  <th>Mã xác thực</th>
                  <th class="th-ref-code">Mã tham chiếu</th>
                  <th>Số tiền</th>
                  <th class="th-description">Mô tả</th>
                  <th>Mã đơn hàng</th>
                  <th>Trạng thái</th>
                  <th>Người tạo</th>
                  <th class="th-created-date">Ngày tạo</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in listItem">
                  <tr :key="index">
                    <td>{{ item.storeName }}</td>
                    <td>{{ item.verifyCode }}</td>
                    <td class="wrapword">{{ item.referenceCode }}</td>
                    <td>
                      <div class="text-right">
                        <span
                          v-if="item.transferAmount > 0"
                          v-text="formatPrice(item.transferAmount)"
                          class="text-right text-report"
                        ></span>
                      </div>
                    </td>
                    <td class="wrapword">{{ item.description }}</td>
                    <td>{{ item.referenceNumber }}</td>
                    <td>
                      <div class="text-left">
                        <span
                          v-text="item.status"
                          v-bind:class="bindClassNameStatus(item.status)"
                        ></span>
                      </div>
                    </td>
                    <td>{{ item.createdBy }}</td>
                    <td>{{ item.createdAt }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <b-row>
              <b-col>
                <p
                  class="mt-3 ml-2 mb-0 text-dark"
                  style="font-weight: 500"
                >
                  Tổng số giao dịch:
                  {{ totalRow }}
                </p>
              </b-col>
              <div class="d-flex justify-content-end">
                <b-col>
                  <b-pagination-nav
                    class="custom-pagination"
                    v-if="numberOfPage >= 2"
                    :link-gen="linkGen"
                    :number-of-pages="numberOfPage"
                    use-router
                    @change="fetchData"
                    align="right"
                    first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                    prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                    next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                    last-class="page-item-last btn btn-icon btn-sm my-1 "
                    page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                  >
                    <template v-slot:first-text>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </template>
                    <template v-slot:prev-text>
                      <i class="ki ki-bold-arrow-back icon-xs"></i>
                    </template>
                    <template v-slot:next-text>
                      <i class="ki ki-bold-arrow-next icon-xs"></i>
                    </template>
                    <template v-slot:last-text>
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </template>
                  </b-pagination-nav>
                </b-col>
              </div>
            </b-row>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { formatPrice } from '@/utils/common';
import * as ENUM from '@/utils/enum';
import * as ENUM_NAME from '@/utils/enum-name';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';

export default {
  mixins: [validationMixin],
  data() {
    return {
      storeId: null,
      storeName: '',
      filteredStoreOptions: [],
      storeOptions: [],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      dateFrom: moment().subtract(15, 'days').format('DD/MM/YYYY'),
      dateTo: moment().format('DD/MM/YYYY'),
      ENUM,
      ENUM_NAME,
      orderNumber: '',
      billNumber: '',
      verificationCode: '',
      referenceCode: '',
      listItem: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: 1,
      count: 1,
      totalRow: 0,
      onLoading: false,
      submitted: false,
      title: '',
    };
  },
  components: {
    KTCodePreview,
    datePicker,
  },
  async created() {
    await this.fetchStoreByUser();
    this.fetchData();
  },
  mounted() {
    this.title = 'Lịch sử thanh toán';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  methods: {
    fetchStoreByUser: async function () {
      await ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.storeId = stores[0].id;
            this.storeName = stores[0].name;
            this.storeOptions.push({ id: this.storeId, name: this.storeName });
          } else {
            this.storeOptions = stores.map((element) => {
              return {
                id: element.id,
                name: element.name,
                shortName: element.shortName,
                suggestionName: element.shortName + ' ( ' + element.name + ')',
              };
            });
            this.filteredStoreOptions = [...this.storeOptions];
          }
        }
      });
    },
    bindClassNameStatus(status) {
      let className = '';
      switch (status) {
        case ENUM_NAME.PAYMENT_STATUS_NAME[ENUM.PAYMENT_STATUS.FAILED]: {
          className = 'badge badge-warning text-white';
          break;
        }
        case ENUM_NAME.PAYMENT_STATUS_NAME[ENUM.PAYMENT_STATUS.SUCCESS]: {
          className = 'badge badge-success';
          break;
        }
      }
      return className;
    },
    formatPrice,
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;
      const listStoreId = this.storeOptions.map((element) => element.id);
      let params = {
        orderNumber: this.orderNumber,
        billNumber: this.billNumber,
        verificationCode: this.verificationCode,
        referenceCode: this.referenceCode,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
          : null,
        size: 10,
        stores: listStoreId,
        page: this.page,
      };
      let paramQuery = {
        params: params,
      };
      this.onLoading = true;
      ApiService.setHeader();
      await ApiService.query('qr-code/histories', paramQuery)
        .then(({ data }) => {
          this.count = 1;
          this.totalRow = data.data.totalItems;
          this.numberOfPage = data.data.totalPages;
          if (data.data.result) {
            this.listItem = [];
            data.data.result.forEach((item) => {
              const itemDTO = {
                storeName: item.storeName,
                verifyCode: item.verifyCode,
                referenceCode: item.paymentCode,
                transferAmount: item.transferAmount || 0,
                description: item.description,
                referenceNumber: item.referenceNumber,
                status: item.paymentStatus,
                createdAt: moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
                createdBy: item.createdBy,
              };

              this.listItem.push(itemDTO);
            });
          }
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.qr-code-history {
  .search-col {
    display: flex;
    align-items: center;
  }
  .reference-code {
    width: 10%;
  }
  .th-created-date {
    width: 8%;
  }
}
</style>

<style scoped>
.th-ref-code {
  width: 10%;
}

.th-description {
  width: 20%;
}

.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}
</style>
